import React from 'react';
import { Link } from 'react-router-dom';
import Container from './Container';
import styles from '../css/Navbar.module.css';
import logo from '../../img/logo.png';
import videoFile from '../../media/Crypto_V1.mkv'
import flagEN from '../../img/flags/en.png';
import flagPT from '../../img/flags/pt.png';
import flagES from '../../img/flags/es.png';
import flagDE from '../../img/flags/de.png';
import flagCN from '../../img/flags/cn.png';
import SessionManager from '../../utils/SessionManager.tsx';

function Navbar() {
 
    const handleClick = (language) => {
        SessionManager.setLanguage(language);
    }

    return (
        <nav className={styles.navbar}>
            <Container className={styles.container}>
                <div className={styles.logotitle}>
                    <Link to="/">
                        <video className={styles.logo} autoPlay loop muted>
                            <source src={videoFile} type="video/mp4" />
                        </video>
                    </Link>
                    <h1 className={styles.title}>Crypto Simulator AI</h1>
                </div>
                <div className={styles.links}>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <Link to="/market">Market</Link>
                        </li>
                        <li className={styles.item}>
                            <Link to="/download">Download</Link>
                        </li>
                        <li className={styles.item}>
                            <Link to="/blog">Blog</Link>
                        </li>
                        <li className={styles.item}>
                            <Link to="/privacy-police">Privacy Policy</Link>
                        </li>
                        <li className={styles.item}>
                            <Link to="/terms-of-service">Terms Of Service</Link>
                        </li>
                    </ul>
                </div>
                <div className={styles.buttons}>
                    

                    <div className={styles.languageSelector}>
                        <img src={flagEN} alt="English" className={styles.flag} onClick={() => handleClick('en')}/>
                        <img src={flagPT} alt="Portuguese" className={styles.flag} onClick={() => handleClick('pt')}/>
                        <img src={flagES} alt="Spanish" className={styles.flag} onClick={() => handleClick('es')} />
                        <img src={flagDE} alt="German" className={styles.flag} onClick={() => handleClick('de')} />
                        <img src={flagCN} alt="Chinese" className={styles.flag} onClick={() => handleClick('zh')} />
                    </div>
                    <Link to="/login" className={`${styles.button} ${styles.login}`}>Log In</Link>
                    {/*<Link to="/register" className={`${styles.button} ${styles.register}`}>Sign Up</Link>*/}
                </div>
            </Container>
        </nav>
    );
}

export default Navbar;
