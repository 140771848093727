import React, {useEffect, useState}  from 'react';
import styles from '../css/Home.module.css'
import Panel from '../components/Panel'
import qrcode from '../img/qrCode_Crypto.png'
import CookieConsentModal from '../components/CookieConsentModal';
import IMAGES from '../constants/Images'
import TypingEffect from '../components/TypingEffect'
import { useLanguage } from '../components/language/LanguageHook';



function Home() {

    const tradutor = useLanguage();
  
    return (
        <div className={styles.home_container}>
            <h1 className={styles.text_top}>
                <TypingEffect text="  Crypto Simulator AI uses artificial intelligence to predict the best opportunities for you. Our neural network examines historical price trends to predict future movements. This helps you identify potential buying or selling opportunities by predicting price directions over time."
                    typingSpeed={50}
                    delay={10000} />
            </h1>

            <div className={styles.container_img}>
                <img className={styles.img_App} src={IMAGES.App4} alt="App4" width="200" ></img>
                <img className={styles.img_App} src={IMAGES.App5} alt="App5" width="200" ></img>
                <img className={styles.img_App} src={IMAGES.App6} alt="App6" width="200" ></img>
            </div>

            <div className={styles.panel_container}>
                <Panel title="Simulations with Artificial Intelligence">
                    <p> Applying Artificial Intelligence in Cryptocurrency Markets.</p>
                </Panel>
                <Panel title="Cryptocurrency market notifications">
                    <p> Stay up to date with the cryptocurrency market with notifications on your cell phone.</p>
                </Panel>
            </div>
            <h1 className={styles.text_center}>
            {tradutor('Available for download')}
            </h1>
            <div className={styles.container_img}>
                <img className={styles.img_qrcode} src={IMAGES.qrcode} alt="qrCode" width="200" ></img>
            </div>
            <CookieConsentModal />
        </div>


    )
}

export default Home