import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguage = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const changeLanguage = async () => {
            const language = await sessionStorage.getItem('language') || 'en'; // 'en' como idioma padrão caso não exista
            if (i18n.changeLanguage) {
                i18n.changeLanguage(language);
            } else {
               // console.error('changeLanguage function is not available in i18n');
            }
        };
        changeLanguage();
    }, [i18n]);

    return t;
};
