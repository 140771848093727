

export interface UserData {
  id: number;
  name: string;
  username: string;
  token: string;
  admin: boolean;
  language: string;
}

export interface BotConfig {
  percent_stop_loss: number;
  percent_stop_gain: number;
  purchase_config_id: number;
  balance: number;
  simulation_mode: boolean,
  force_sale: boolean,
  config_active: boolean
};

class SessionManager {
  private static USER_KEY = 'USER_SESSION_KEY';
  private static INICIAL_ACCESS_KEY = 'INICIAL_ACCESS_KEY';
  private static LANGUAGE = 'language';
  private static CRYPTO_CONVERTER = 'CRYPTO_CONVERTER';
  private static THEME = 'THEME'
  private static BOT_CONFIG = 'BOT_CONFIG';


  static async saveUser(user: UserData): Promise<void> {
    try {
      const userJSON = JSON.stringify(user);
      await sessionStorage.setItem(this.USER_KEY, userJSON);
    } catch (error) {
      console.error('Error saving user:', error);
    }
  }

  static async getUser(): Promise<UserData | null> {
    try {
      const userJSON =  sessionStorage.getItem(this.USER_KEY);
      return userJSON ? JSON.parse(userJSON) : null;
    } catch (error) {
      console.error('Error getting user:', error);
      return null;
    }
  }

  static async getUserValue<T extends keyof UserData>(key: T): Promise<UserData[T] | null> {
    try {
        const userString =  sessionStorage.getItem(this.USER_KEY);
        if (userString != null) {
            const userData: UserData = JSON.parse(userString);

            if (key in userData) {
                return userData[key];
            } else {
                console.warn(`Key ${key} does not exist in userData.`);
                return null;
            }
        } else {
            console.warn('No user found.');
            return null;
        }
    } catch (error) {
        console.error('Error getting user value:', error);
        return null;
    }
}

  static async getToken(): Promise<string | null> {
    const user = await this.getUser();
    return user ? user.token : null;
  }

  static async getUserName(): Promise<string> {
    try {
      const user = await this.getUser();
      return user ? user.name.split(' ')[0] : '';
    } catch (error) {
      console.error('Error getting user name:', error);
      return '';
    }
  }

  static async updateUserName(newName: string): Promise<boolean> {
    try {
      const user = await this.getUser();
      if (user) {
        user.name = newName;
        await sessionStorage.setItem(this.USER_KEY, JSON.stringify(user));
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error updating user name:', error);
      return false;
    }
  }

  static async getUserEmail(): Promise<string> {
    try {
      const user = await this.getUser();
      return user ? user.username : '';
    } catch (error) {
      console.error('Error getting user email:', error);
      return '';
    }
  }

  static async updateUserEmail(newEmail: string): Promise<boolean> {
    try {
      const user = await this.getUser();
      if (user) {
        user.username = newEmail;
        await sessionStorage.setItem(this.USER_KEY, JSON.stringify(user));
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error updating user name:', error);
      return false;
    }
  }

  static async getLanguage(): Promise<string> {
    try {
      const language = await sessionStorage.getItem(this.LANGUAGE);
      if(language){
        return language;
      }else{

        const language = 'en';
        return language //devicelanguage;
      }
    } catch (error) {
      console.error('Error getting Language:', error);
      return 'en';
    }
  }


  static async setLanguage(language: string): Promise<void> {
    try {
      sessionStorage.setItem(this.LANGUAGE, language);
    } catch (error) {
      console.error('Error saving Language:', error);
      throw error;
    }
  }

  static async getCryptoConverter(): Promise<string> {
    try {
      const crypto = await sessionStorage.getItem(this.CRYPTO_CONVERTER);
      return crypto ? crypto : '';
    } catch (error) {
      console.error('Error getting Crypto:', error);
      return '';
    }
  }

  static async setCryptoConverter(crypto: string): Promise<void> {
    try {
      await sessionStorage.setItem(this.CRYPTO_CONVERTER, crypto);
    } catch (error) {
      console.error('Error saving Crypto:', error);
      throw error;
    }
  }

  static async logout(): Promise<void> {
    try {
      await sessionStorage.removeItem(this.USER_KEY);
      await sessionStorage.removeItem(this.BOT_CONFIG);
    } catch (error) {
      console.error('Error clearing user:', error);
    }
  }

  static async isPrimaryAccess(): Promise<boolean> {
    try {
      const infoIni = await sessionStorage.getItem(this.INICIAL_ACCESS_KEY);
      return infoIni === null || infoIni.trim() === '';
    } catch (error) {
      console.error('Error getting info Primary Access:', error);
      throw error;
    }
  }

  static async setPrimaryAccess(): Promise<void> {
    try {
      const info = JSON.stringify({ IsPrimary: false });
      await sessionStorage.setItem(this.INICIAL_ACCESS_KEY, info);
    } catch (error) {
      console.error('Error saving Primary Access:', error);
      throw error;
    }
  }

  static async setTheme(theme: string): Promise<void> {
    try {
      await sessionStorage.setItem(this.THEME, theme);
    } catch (error) {
      console.error('Error saving Theme:', error);
      throw error;
    }
  }

  static async getTheme(): Promise<string> {
    try {
      const theme = await sessionStorage.getItem(this.THEME);
      return theme ? theme : '';
    } catch (error) {
      console.error('Error getting Theme:', error);
      return '';
    }
  }

  static async saveConfig(config: BotConfig): Promise<boolean> {
    try {
      await sessionStorage.setItem(this.BOT_CONFIG, JSON.stringify(config));
      return true;
    } catch (error) {
      console.error('Error saving bot config:', error);
      return false;
    }
  }

  static async getConfig(): Promise<BotConfig | null> {
    try {

      const configString = await sessionStorage.getItem(this.BOT_CONFIG);
      if (configString != null) {
         const botConfig: BotConfig = JSON.parse(configString)
        return botConfig
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error retrieving bot config:', error);
      return null;
    }
  }

  static async getConfigValue<T extends keyof BotConfig>(key: T): Promise<BotConfig[T] | null> {
    try {
        const configString = await sessionStorage.getItem(this.BOT_CONFIG);

        if (configString != null) {
            const botConfig: BotConfig = JSON.parse(configString);

            if (key in botConfig) {
                return botConfig[key];
            } else {
                console.warn(`Key ${key} does not exist in BotConfig.`);
                return null;
            }
        } else {
            console.warn('No configuration found.');
            return null;
        }
    } catch (error) {
        console.error('Error getting configuration value:', error);
        return null;
    }
}

  static async updateConfig(newConfig: Partial<BotConfig>): Promise<boolean> {
    try {
      const currentConfig = await this.getConfig();

      if (currentConfig) {
        const updatedConfig = { ...currentConfig, ...newConfig };
        await sessionStorage.setItem(this.BOT_CONFIG, JSON.stringify(updatedConfig));
      } else {
         await sessionStorage.setItem(this.BOT_CONFIG, JSON.stringify(newConfig));
      }
      return true;
    } catch (error) {
      console.error('Error upserting bot config:', error);
      return false;
    }
  }

  static async updateConfigActive(config_active: boolean): Promise<boolean> {
    try {

      const currentConfig = await this.getConfig();

      if (currentConfig) {
        const updatedConfig = { ...currentConfig, config_active };

        await sessionStorage.setItem(this.BOT_CONFIG, JSON.stringify(updatedConfig));
      } else {
        const newConfig = { config_active };
        await sessionStorage.setItem(this.BOT_CONFIG, JSON.stringify(newConfig));
      }
      return true;
    } catch (error) {
      console.error('Error updating bot config_active:', error);
      return false;
    }
  }

  static async RemoveConfig(newConfig: Partial<BotConfig>): Promise<boolean> {
    try {
      const currentConfig = await this.getConfig();
      if (currentConfig) {
        await sessionStorage.removeItem(this.BOT_CONFIG);
      }
      return true;
    } catch (error) {
      console.error('Error upserting bot config:', error);
      return false;
    }
  }

}

export default SessionManager;
