
import axios from 'axios';
import React, { createContext, useState, useContext } from 'react';
import {getApiLogin} from '../utils/Api'
import CookieManager from '../utils/CookiesManager';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);

  const login = async (username, password) => {
    try {
      console.log('chegou login 2')
      const response = await axios.post(getApiLogin(), {
        username,
        password,
      });
      CookieManager.setCookie('auth', response.data.sessao.token, {
        expires: 1, 
        secure: process.env.NODE_ENV === 'production', 
        sameSite: 'Strict', 
        httpOnly: true,
        path: '/', 
      })
      setAuth(response.data.token);
      return response.data.token;
    } catch (error) {

      throw error;
    }
  };

  const logout = () => {
    setAuth(null);
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
    
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
