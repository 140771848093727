import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'
import styles from '../css/Footer.module.css'
import { FaXTwitter, FaGooglePlay } from 'react-icons/fa6';
function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className={styles.footer}>
            <ul className={styles.social_list}>
                {/*<li>
                    <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
                        <FaXTwitter />
                    </a>
                </li>*/}
                <li>
                    <a href="https://www.facebook.com/profile.php?id=61564763923164" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                    </a>
                </li>
                {/*<li>
                    <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </a>
                </li>*/}
                <li>
                    <a href="https://www.linkedin.com/in/crypto-simulator-ai" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/@CryptoSimulatorAI" target="_blank" rel="noopener noreferrer">
                        <FaYoutube />
                    </a>
                </li>
                <li>
                    <a href="https://play.google.com/store/apps/details?id=com.cryptosimulatorai.app&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                        <FaGooglePlay />
                    </a>
                </li>
                
            </ul>
            <p>
                <span className={styles.copy_right}> Crypto Simulator AI &copy; {currentYear}</span>
            </p>
        </footer>
    )
}
export default Footer