import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth/AuthContext';
import styles from '../css/Login.module.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // Estado para armazenar a mensagem de erro
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); // Mova o preventDefault aqui
    setErrorMessage(''); // Limpa a mensagem de erro antes de tentar o login

    try {
      await login(username, password);
      navigate('/dashboard');
    } catch (error) {
      setErrorMessage('Falha no login. Verifique suas credenciais.'); // Define a mensagem de erro
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <h2>Login</h2>

        {/* Exibir alerta se houver uma mensagem de erro */}
        {errorMessage && (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert variant="outlined" severity="warning">{errorMessage}</Alert>
          </Stack>
        )}

        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <div className={styles.inputGroup}>
            <label>Email:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Password:</label>
            <div className={styles.passwordWrapper}>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={styles.input}
              />
              <span onClick={togglePasswordVisibility} className={styles.passwordIcon}>
                {showPassword ? <FaEyeSlash style={{ color: 'black' }} /> : <FaEye style={{ color: 'black' }} />}
              </span>
            </div>
          </div>
          <button type="submit" className={styles.loginButton}>Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
