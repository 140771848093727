import './css/App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Download from './screens/Download'
import Market from './screens/Market'
import Home from './screens/Home'
import Blog from './screens/Blog'
import ChatAI from './screens/ChatAI'
import Company from './screens/Company'
import Contact from './screens/Contact'
import Login from './screens/Login'
import Register from './screens/Register'
import DashBoard from './screens/dashboard/Dashboard'
import PrivacyPolicy from './screens/PrivacyPolicy'
import TermsOfService from './screens/TermsOfService'
import Container from './components/layout/Container'
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
import {AuthProvider} from './Auth/AuthContext';
import PrivateRoute from './Auth/PrivateRoute';

function App() {
    return (
        <Router>
            <AuthProvider>
                <Navbar />
                <Container customclass="min-height">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/market" element={<Market />} />                        
                        <Route path="/download" element={<Download />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/login" element={<Login/>} />
                        <Route path="/register" element={<Register/>} />
                        <Route path="/privacy-police" element={<PrivacyPolicy />} />
                        <Route path="/terms-of-service" element={<TermsOfService />} />

                       
                            <Route path="/dashboard" element={<DashBoard />} />
                     
                    </Routes>
                </Container>
                <Footer />
            </AuthProvider>
        </Router>
    );
}

export default App;
