
import Cookies from 'js-cookie';
import { Attributes } from 'react';

const COOKIE_NAME = 'jwt_token';
const COOKIE_EXPIRATION_DAYS = 30;

  const CookieManager = {

    setCookie: (name: string, value: string | number | object, options: Attributes = {}) => {
      try {

        const cookieValue = typeof value === 'object' ? JSON.stringify(value) : String(value);
        Cookies.set(name, cookieValue, options);
        console.log(`Cookie ${name} salvo com sucesso!`);
      } catch (error) {
        console.error('Erro ao salvar cookie:', error);
      }
    },
  
    getCookie: (name: string): string | object | null => {
      try {
        const cookieValue = Cookies.get(name);
        if (cookieValue != null ) {
          try {
            return JSON.parse(cookieValue);
          } catch {
            return cookieValue;
          }
        } else {
          console.warn(`Cookie ${name} não encontrado.`);
          return null;
        }
      } catch (error) {
        console.error('Erro ao recuperar cookie:', error);
        return null;
      }
    },
  
    removeCookie: (name: string) => {
      try {
        Cookies.remove(name);
        console.log(`Cookie ${name} removido com sucesso!`);
      } catch (error) {
        console.error('Erro ao remover cookie:', error);
      }
    }
  };


export default CookieManager;
